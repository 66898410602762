

import React from 'react'
import SeoPage from '../components/SeoPage'
import UpperForm from '../components/UpperForm'
import BottomForm from '../components/BottomForm'
import { StaticImage } from 'gatsby-plugin-image'
import LeadImage from '../images/etablissement-extinction-servitude-avocat-scaled.jpg'
import { graphql } from 'gatsby'
import ConversionLink from '../components/ConversionLink'


const BlogPost25 = ({ data, location, ...props }) => (
    <SeoPage location={location}
        title="Quels sont les services de l’avocat en matière de servitude? Établissement et extinction de servitudes avec un avocat! - Soumissions Avocat"
        description="Problème de servitude à l’horizon? Demandez conseil à un avocat en droit immobilier pour faire respecter votre droit!"
        image={LeadImage}>
        <h1>Quels sont les services de l’avocat en matière de servitude? Établissement et extinction de servitudes avec un avocat!</h1>

					
					
						<p><strong>La servitude est un droit assez poussiéreux.</strong> En effet, cette charge grevée à un immeuble fait partie de la tradition juridique québécoise depuis belle lurette. Toutefois, une forte majorité des propriétaires immobiliers de la province côtoient de près ce droit rattaché à la propriété sans même en connaître les bases.</p>
<p><StaticImage alt="etablissement extinction servitude avocat" src="../images/etablissement-extinction-servitude-avocat-scaled.jpg" placeholder="blurred" imgStyle={{ maxWidth: '80%', margin: '0 auto' }}></StaticImage></p>
<p>Pourtant, la servitude est un droit qui est toujours omniprésent et que tout propriétaire immobilier se doit de la connaître. Cela est d’autant plus vrai si vous rencontrez un problème de servitude suite à l’achat d’une maison ou d’un terrain. Pour vous guider, Soumissions Avocat vous explique le fonctionnement de l’établissement et de l’extinction des servitudes en droit Québécois!</p>
<p><strong>Pour tous vos questionnements en matière de servitude, consultez un avocat en droit immobilier référé par Soumissions Avocat!</strong></p>
<h2>Qu’est-ce qu’une servitude et quelle est son utilité?</h2>
<p>Sans même que vous ne le sachiez, une servitude est probablement rattachée au fonds sur lequel votre maison est bâtie. En effet, la quasi-totalité des terrains au Québec est grevée de servitudes en faveur de leur municipalité ou encore en faveur de Hydro-Québec. Mais à quoi sert un tel droit et au bénéfice de qui est-il instauré?</p>
<p>La servitude peut notamment<strong> faciliter ou permettre l’accès à un fonds. </strong>Effectivement, il arrive que des propriétaires immobiliers soient dans une situation d’enclave, signifiant qu’ils ne disposent d’aucun accès à la voie publique à partir de leur terrain. Cela les contraint à demander une servitude de passage à un des propriétaires contigus.</p>
<p>Il est également possible d’instaurer une servitude<strong> dans le but d’empêcher une construction future.</strong> Une telle servitude dite de « non-construction » peut interdire l’érection d’immeubles sur le fonds en question ou encore en limiter la hauteur et autre caractéristique.</p>
<p>Un droit comme la servitude peut aussi<strong> permettre la continuation d’une situation existante, mais illicite</strong>, notamment en matière de vues. Le Code civil prévoit des normes d’éloignement minimales concernant les fenêtres placées du côté d’une ligne mitoyenne. Les propriétaires immobiliers détenant des vues trop rapprochées pourraient donc se consentir une servitude de vue réciproque.</p>
<p>La servitude peut prendre de nombreuses formes toutes plus inusitées les unes que les autres. Les exemples de servitudes de baignade dans un lac ou de droit de chasse sur une terre sont quelques exemples illustratifs.</p>
<p>Existe-t-il une différence entre la servitude personnelle et la servitude réelle? La servitude réelle est celle qui est rattachée à un terrain ou un immeuble, et ce, de façon permanente.  Sur le plan des effets juridiques, une servitude réelle produit les mêmes effets que la servitude personnelle, mais cette dernière étant rattachée à une personne spécifique, elle est consentie pour une durée temporaire.</p>
<h2>Comment mettre en place une servitude?</h2>
<p>Avant de se prévaloir des droits conférés pas une servitude, encore faut-il la constituer. C’est à ce stade qu’un avocat ou un notaire peut vous aider à mettre en place l’acte constitutif de servitude par l’un des moyens suivants!</p>
<p><StaticImage alt="modes etablissement servitude avocat" src="../images/modes-etablissement-servitude-avocat-scaled.jpg" placeholder="blurred" imgStyle={{ maxWidth: '80%', margin: '0 auto' }}></StaticImage></p>
<p><strong>Par testament,</strong> le testateur peut créer un acte de servitude prévoyant qu’un tel droit prendra effet à son décès suite à l’ouverture du testament. Cette stipulation se fera en faveur d’un légataire lorsque l’immeuble ou terrain sera transféré à une autre personne.</p>
<p>Lorsque la servitude est<strong> établie par contrat, </strong>un tel acte juridique peut être accessoire à un acte de vente ou encore porter uniquement sur la constitution de la servitude. Il est fréquent de voir un acte de vente de maison conférer une servitude, notamment en faveur d’un promoteur ou de futurs acquéreurs.</p>
<p>La servitude dite<strong> « par destination du propriétaire » </strong>est un cas de figure unique. Il s’agit de la situation d’une personne propriétaire de deux fonds contigus ou d’un seul fonds dont il anticipe la division. Ce dernier grève ainsi une servitude en faveur de l’un de ces fonds (ou partie de fonds) au bénéfice de l’autre.</p>
<p>La particularité est que cette servitude ne prend effet que le jour où le fonds est effectivement divisé et que le nouveau fonds créé est acquis par un propriétaire différent, soit par la vente ou la donation. D’ici là, la servitude ne produit aucun effet juridique.</p>
<p>Il est également possible qu’une servitude existe à<strong> par le simple effet de la loi</strong>. On fait ici référence à la servitude que les municipalités instaurent à leur propre bénéfice ou encore celles que la société d’État Hydro-Québec se réserve afin d’effectuer les travaux nécessaires.</p>
<h2>Toutes les obligations que les propriétaires des fonds dominants et servants doivent respecter!</h2>
<p>La servitude est génératrice de droits et d’obligations, quelle qu’en soit sa nature. Cela s’avère tout aussi vrai du côté du fonds servant que dominant, qui supportent tous deux des obligations distinctes, mais corrélatives. Voici donc à quoi s’engagent les propriétaires visés par la servitude.</p>
<p><strong>Propriétaire du fonds dominant (Celui en faveur de qui la servitude est créée)</strong></p>
<p>Utiliser la servitude et ses accessoires : Le principal droit du propriétaire dominant est d’utiliser la servitude, mais ce droit inclut également le droit de faire usage des accessoires nécessaires au principal. Par exemple, une servitude de baignade dans un lac entraîne la nécessité de passer sur le terrain qui y donne accès.</p>
<p><strong>L’entretien</strong> : À moins que les propriétaires servants et dominants n’aient prévu autrement, c’est ce dernier qui assume la charge et frais d’entretien nécessaires pour exercer la servitude.</p>
<p><strong>Les ouvrages </strong>: Le propriétaire dominant est en droit d’effectuer les ouvrages nécessaires à l’usage et à la conservation de la servitude. Il doit cependant en assumer les frais. Avertir le propriétaire du fonds servant de la tenue de ces ouvrages fait également partie des obligations auxquelles s’engage le propriétaire dominant.</p>
<p><strong>Propriétaire du fonds servant (Celui qui assume la charge de la servitude)
</strong>
<strong>Supporter la charge imposée</strong> : L’obligation du propriétaire du fonds servant est souvent qualifiée de « passive », signifiant qu’elle ne lui exige de poser aucun geste, seulement de supporter ceux du propriétaire du fonds dominant. Ainsi, le propriétaire du fonds servant n’a pas le droit de compromettre l’exercice de la servitude par le propriétaire du fonds dominant.</p>
<p><strong>Demander le déplacement de l’assiette :</strong> Le propriétaire servant est en droit de demander le déplacement de l’assiette de la servitude, soit l’endroit précis où elle s’exerce. Il doit avoir un intérêt réel pour qu’une telle demande soit justifiée et consentie.</p>
<p><strong>Le propriétaire du fonds servant ou dominant ne respecte pas les dispositions de l’acte de servitude?</strong> Vous savez ce que vous avez à faire : contactez un avocat partenaire de Soumissions Avocat qui s’y connait en droit immobilier, c’est votre meilleure chance d’obtenir gain de cause!</p>
<h2>Extinction d’une servitude : comment mettre fin à ce droit?</h2>
<p>Toute bonne chose ayant une fin, il est effectivement possible de mettre un terme à une servitude, mais à certaines conditions seulement. Comme la servitude est de nature perpétuelle, il faudra absolument entrer dans les conditions ci-bas pour éteindre un tel droit.</p>
<p><StaticImage alt="comment annuler eteindre servitude avocat" src="../images/comment-annuler-eteindre-servitude-avocat.jpg" placeholder="blurred" imgStyle={{ maxWidth: '80%', margin: '0 auto' }}></StaticImage></p>
<p>Par le rachat. Fort d’une entente entre le propriétaire du fonds servant et du fonds dominant, il est possible d’éteindre la servitude en rachetant la charge que celle-ci imposait sur le fonds. Aucune règle spécifique ne gouverne le rachat de servitude, les parties étant libres de s’entendre sur le prix et les conditions.</p>
<p>Non-usage pendant 10 ans. Le propriétaire du fonds servant peut demander l’extinction d’une servitude s’il arrive à faire la preuve de son non-usage pour une durée de 10 ans. La qualification du non-usage se rattache évidemment au type de servitude consenti, à savoir s’il s’agit d’un droit de passage, de baignade, etc.
<strong>
Renonciation du propriétaire du fonds dominant. </strong>Il revient au propriétaire du fonds dominant et non au propriétaire du fonds servant de renoncer à la servitude. Cette renonciation doit toujours être expresse et ne peut en aucun cas être présumée ou tacite.</p>
<p>L’arrivée du terme stipulé. On rencontre rarement de telles servitudes étant donné qu’elles sont généralement consenties de manière perpétuelle. Il est toutefois possible de prévoir une expiration de la servitude; celle-ci s’éteint une fois ce délai atteint.</p>
<p>Réunion de la qualité de propriétaire du fonds servant et dominant. Une servitude est instaurée au bénéfice du propriétaire dominant alors que le propriétaire servant en assume le fardeau.</p>
<p>Il va de soi que la réunion en la même personne du statut de propriétaire servant et dominant met fin à la servitude, car la raison d’être de la servitude perd tout son sens.</p>
<h2>Réglez rapidement votre problème de servitude avec un avocat partenaire de Soumissions Avocat!</h2>
<p><strong>Le propriétaire du fonds servant vous empêche d’exercer votre servitude?</strong> Le propriétaire dominant abuse des droits octroyés par celle-ci? Dans un cas comme dans l’autre, l’assistance d’un avocat qualifié est de mise avant quel les troubles entre les propriétaires ne dégénèrent en litige!</p>
<p>Un avocat peut également vous aider à mettre en place un acte de servitude ou encore à en invoquer l’extinction. Dans un cas comme dans l’autre, l’intervention d’un avocat en droit immobilier est indispensable.</p>
<p><strong>C’est votre jour de chance, car Soumissions Avocat est justement en mesure d’assurer une mise en contact gratuite et rapide entre vous et un avocat qualifié près de chez vous!</strong></p>
<p><strong>Obtenez la réponse à toutes vos questions en matière de servitudes simplement en remplissant le formulaire de demande en bas de page : c’est gratuit et sans engagement de votre part!</strong></p>
<p>
					</p>
    </SeoPage>
)
export default BlogPost25
  